<template>
  <v-container style="max-width: 600px; height: 100%" class="pa-0">
    <v-dialog v-model="dialog.visible" fullscreen>
      <v-card color="grey lighten-4">
        <v-toolbar flat>
          <v-btn icon @click="dialog.visible = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <div class="pa-6 white">
          <div class="text-caption text-right grey--text text--darken-2">
            {{ $dayjs(dialog.message.updateAt).format('YYYY年 MM月 DD日(ddd) H:mm') }}
          </div>
          <div class="u-pre-wrap text-h5 my-3" v-text="dialog.message.title"></div>
          <v-divider></v-divider>
          <div class="u-pre-wrap mt-3" v-text="dialog.message.content"></div>
          <!-- <div class="text-body-1 grey--text text--darken-3 font-weight-medium">
            {{ dialog.message.content }}
          </div> -->
        </div>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
    <v-list
      class="overflow-y-auto py-0"
      two-line
      v-for="message in messages"
      v-bind:key="message._id"
    >
      <v-list-item class="" @click="showModel(message)">
        <v-list-item-content class="py-8">
          <v-list-item-title class="text-h6">{{ message.title }}</v-list-item-title>
          <v-list-item-subtitle class="text-caption">
            {{ $dayjs(message.updateAt).format('YYYY年 MM月 DD日(ddd) H:mm') }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
    </v-list>
    <v-btn block v-on:click="fetchMessages">more</v-btn>
  </v-container>
</template>

<script>
  import Methods from '@/api/method.js';

  export default {
    name: 'Message',

    data() {
      return {
        dialog: {
          visible: false,
          message: {},
        },
        messagePage: 0,
        messages: [],
      };
    },

    mounted() {
      this.fetchMessages();
    },

    methods: {
      async fetchMessages() {
        try {
          let { data } = await Methods.GetMessages({
            user_id: this.$store.state.user.user_id,
            // user_id: '632975baf7307c037aee91a9',
            page: this.messagePage,
            perPage: 10,
          });
          console.log(data);
          this.messages = [...this.messages, ...data.messages];
          this.messagePage += 1;
        } catch (error) {
          console.log(error);
        }
      },
      showModel(data) {
        this.dialog.message = data;
        this.dialog.visible = true;
      },
    },
  };
</script>
<style scoped>
  .u-pre-wrap {
    white-space: pre-wrap;
  }
</style>
